<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="detail_dialog" :top="'2vh'" :fullscreen="isExpand"
    :show-close="false" :before-close="handleClose">
    <template slot="title">
      <div class="title-box">
        <span>寄修单详情</span>
        <span class="icon-box">
          <img :src="!isExpand ? expandSvg : collapseSvg" @click="isExpand = !isExpand">
          <i class="el-icon-close" @click="handleClose"></i>
        </span>
      </div>
    </template>
    <div class="content">
      <div class="content__main">
        <div class="content__main-flag">
          <img :src="flagSvg">
        </div>
        <div class="content__main-header">
          <div class="content__main-header-order">寄修单号：{{ detail.code }}</div>
          <div class="content__main-header-name">
            <template>
              {{ detail.userParty }}_{{ detail.code }}
            </template>
            <span v-if="detail.status" :class="['c_tag', detail.status ? `status-${detail.status}` : '']">
              {{ detail.statusMsg }}
            </span>
          </div>
        </div>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="寄修信息" name="order">
          <detail class="pane" :info="detail"></detail>
        </el-tab-pane>
        <el-tab-pane label="日志动态" name="log">
          <Log ref="log" class="pane" :id="id"></Log>
        </el-tab-pane>
      </el-tabs>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关闭</el-button>
      <slot name="button"></slot>
    </span>
  </el-dialog>
</template>
<script>
import Detail from './detail.vue';
import Log from '../../components/log.vue';
import * as Moment from 'dayjs';
import { sendRepairDetail } from '@/api/logistics.js';

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
      default: '',
    },
  },
  components: {
    Detail,
    Log,
  },
  watch: {
    activeName (name) {
      if (name === 'order') {
        this.getDetail();
      } else if (name === 'log') {
        this.$refs.log.init();
      }
    },
  },
  data () {
    return {
      isExpand: false,
      expandSvg: require('@/assets/ic_expand.svg'),
      collapseSvg: require('@/assets/ic_collapse.svg'),
      flagSvg: require('@/assets/document_checked.svg'),
      activeName: 'order',
      detail: {},
    };
  },
  methods: {
    handleClose () {
      this.$emit('update:dialogVisible', false);
    },
    formatDate (text) {
      return text ? Moment(text).format('YYYYMMDD') : '';
    },
    getDetail () {
      sendRepairDetail({
        id: this.id,
        findLogisticsTrackFlag: true,
        findRecordFlag: true,
      }).then(({ body }) => {
        this.detail = body || {};
      });
    },
  },
  mounted () {
    this.getDetail();
  },
};
</script>

<style scoped lang="scss">
.detail_dialog {
  /deep/ .el-dialog__header {
    padding: 16px 20px;
  }

  .title-box {
    color: #303133;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-box {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;

        &:hover {
          cursor: pointer;
        }
      }

      i {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .content__main {
    display: flex;
    align-items: center;
    position: relative;

    .content__main-header {
      margin-left: 12px;

      .content__main-header-order {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
      }

      .content__main-header-name {
        height: 32px;
        line-height: 32px;
        font-size: 16px;
        font-weight: 600;

        .c_tag {
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
        }

        .c_tag {
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
        }

        .status-2, .status-3 {
            background-color: #FEFAE0;
            color: #FAB007;

            &:before {
              color: #FAB007;
            }
          }

          .status-4, .status-5, .status-1 {
            background: #E2F3FE;;
            color: #237FFA;

            &:before {
              color: #237FFA;
            }
          }

          .status-8 {
            background-color: #F2F4F7;
            color: #1F2733;

            &:before {
              color: #1F2733;
            }
          }
      }
    }
  }

  .pane {
    height: calc(90vh - 220px);
    overflow: auto;
  }
}
</style>
