<template>
  <el-dialog
    :visible.sync="dialogVisible"
    custom-class="detail_dialog"
    :top="'2vh'"
    :fullscreen="isExpand"
    :show-close="false"
    :before-close="handleClose"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <template slot="title">
      <div class="title-box">
        <span>{{ type === 1 ? '设备寄回' : '新增备用机寄出' }}</span>
        <span class="icon-box">
          <img :src="!isExpand ? expandSvg : collapseSvg" @click="isExpand = !isExpand" />
          <i class="el-icon-close" @click="handleClose"></i>
        </span>
      </div>
    </template>
    <el-form ref="form" :model="form" :rules="rules">
      <el-row>
        <el-col :span="12">
          <el-form-item label="快递公司" prop="courierCode" label-width="8em">
            <el-select v-model="form.courierCode" placeholder="请选择快递公司" clearable filterable>
              <el-option
                v-for="opt in expressOptions"
                :key="opt.optionCode"
                :label="opt.optionName"
                :value="opt.optionCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="快递单号" prop="courierNumber" label-width="8em">
            <el-input v-model="form.courierNumber" placeholder="请输入快递单号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item :label="`${type === 1 ? '寄回' : '寄出'}设备图片`" prop="deviceAnnexList" label-width="8em">
            <Upload
              v-model="form.deviceAnnexList"
              accept="image/png,image/jpeg,image/jpg,image/bmp"
              @remove="v => handleRemove(v, 'deviceAnnexList')"
              :maxSize="10"
              size="origin"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item :label="`${type === 1 ? '寄回' : '寄出'}包装图片`" prop="wrapAnnexList" label-width="8em">
            <Upload
              v-model="form.wrapAnnexList"
              accept="image/png,image/jpeg,image/jpg,image/bmp"
              @remove="v => handleRemove(v, 'wrapAnnexList')"
              :maxSize="10"
              size="origin"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item :label="`${type === 1 ? '寄回' : '寄出'}备注`" prop="remark" label-width="8em">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="3"
              :placeholder="`请输入${type === 1 ? '寄回' : '寄出'}备注`"
              :maxlength="200"
              show-word-limit
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="info" plain @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSave">确定{{ `${type === 1 ? '寄回' : '寄出'}` }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { sendRepairGoodsSendBack, sendRepairSpareSendOut } from '@/api/logistics.js';
import { dictionaryData } from '@/api/inquiryForm.js';

export default {
  props: {
    type: {
      type: Number,
      default: 1, // 1 -> 寄修， 2 -> 备用机
    },
  },
  data () {
    return {
      dialogVisible: false,
      isExpand: false,
      expandSvg: require('@/assets/ic_expand.svg'),
      collapseSvg: require('@/assets/ic_collapse.svg'),
      form: {
        courierName: '',
        courierCode: '',
        courierNumber: '',
        deviceAnnexList: [],
        wrapAnnexList: [],
        remark: '',
      },
      rules: {
        courierCode: [{ required: true, message: '请选择快递公司', trigger: 'change' }],
        courierNumber: [{ required: true, message: '请输入快递单号', trigger: 'blur' }],
        deviceAnnexList: [{ required: true, message: '请选择签收设备图片', trigger: 'change' }],
        wrapAnnexList: [{ required: true, message: '请选择签收包装图片', trigger: 'change' }],
      },
      expressOptions: [],
    };
  },
  methods: {
    init (id) {
      if (!this.expressOptions?.length) {
        this.getExpressDictionaryData();
      }

      this.dialogVisible = true;

      this.$nextTick(() => {
        this.$refs.form.resetFields();

        this.form = this.$options.data().form;

        if (this.type === 1) {
          this.form.id = id;
        } else if (this.type === 2) {
          this.form.inquirePriceId = id;
        }
      });
    },
    handleClose () {
      this.dialogVisible = false;
    },
    handleSave () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const api = this.type === 1 ? sendRepairGoodsSendBack : sendRepairSpareSendOut;

          const obj = this.expressOptions.find(o => o.optionCode === this.form.courierCode);

          const { heads } = await api({
            ...this.form,
            courierName: obj.optionName,
            deviceAnnexList: this.form.deviceAnnexList.map(v => ({
              annexName: v.attachName,
              url: v.url,
            })),
            wrapAnnexList: this.form.wrapAnnexList.map(v => ({
              annexName: v.attachName,
              url: v.url,
            })),
          });

          if (heads.code === 200) {
            this.$message.success(`${this.type === 1 ? '寄回' : '寄出'}成功`);
            this.handleClose();
            this.$emit('callback');
          } else {
            this.$message.error(heads.message);
          }
        }
      });
    },
    handleRemove (file, key) {
      const index = this.form[key].findIndex(v => v.url === file.url);
      this.form[key].splice(index, 1);
    },
    async getExpressDictionaryData () {
      const { body } = await dictionaryData({
        enableFlag: 1,
        typeCode: 'EXPRESS_COMPANY',
      });

      this.expressOptions = body;
    },
  },
};
</script>

<style scoped lang="scss">
.detail_dialog {
  /deep/ .el-dialog__header {
    padding: 16px 20px;
  }

  .title-box {
    color: #303133;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-box {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;

        &:hover {
          cursor: pointer;
        }
      }

      i {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
